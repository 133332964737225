<template>
  <v-app class="user-profile custom-app">
    <v-container fill-height grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex xs12 md8>
          <v-card title="Edit Profile">
            <v-card>
              <v-row class="pas-avatar" size="80">
                <v-col justify="center" align="center" cols="12">
                  <v-avatar>
                    <v-icon dark>account_circle</v-icon>
                    <v-avatar size="1000">
                      <v-img
                        :src="editedUser.logo"
                        class="black--text align-end img-fit"
                        style="width:400px; height:200px"
                      >
                        <!--<v-card-title>{{editedUser.logo}}</v-card-title>-->
                      </v-img>
                    </v-avatar>
                  </v-avatar>
                  <v-file-input
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Logo"
                    prepend-icon="mdi-camera"
                    class="pl-2"
                    v-model="editedUser.profileImage"
                    ><template v-slot:label>
                      {{ $t("avatar") }}
                    </template>
                  </v-file-input>
                </v-col>
                <v-col justify="center" align="center" cols="12">
                  <h5>{{ role }} {{ $t("admin") }}</h5>
                  <h4>{{ editedUser.firstName }} {{ editedUser.lastName }}</h4>
                </v-col>
                <v-card-text></v-card-text>
              </v-row>
            </v-card>

            <v-form class="edit-form fix-for-nepali-label">
              <v-container py-0>
                <v-layout wrap>
                  <v-flex xs12 md6>
                    <v-text-field v-model="editedUser.firstName"
                      ><template v-slot:label>
                        {{ $t("first_name") }}
                      </template></v-text-field
                    >
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="editedUser.lastName"
                      ><template v-slot:label>
                        {{ $t("last_name") }}
                      </template></v-text-field
                    >
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="editedUser.address"
                      ><template v-slot:label>
                        {{ $t("address") }}
                      </template></v-text-field
                    >
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field v-model="editedUser.phone" type="number"
                      ><template v-slot:label>
                        {{ $t("phone") }}
                      </template></v-text-field
                    >
                  </v-flex>
                  <v-row xs12 text-xs-right class="update">
                    <v-btn
                      class="mx-0 font-weight-light justify-center"
                      color="general"
                      @click="updateData"
                      >{{ $t("update_profile") }}
                    </v-btn>
                  </v-row>
                </v-layout>
              </v-container>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import store from "@/store";
import axios from "axios";

export default {
  data() {
    return {
      editedUser: {
        firstName: "",
        lastName: "",
      },
      role: "",
    };
  },
  mounted() {
    this.formData();
  },
  methods: {
    formData() {
      let data = JSON.parse(store.getters.getInfo);
      this.role = data.role;
      axios
        .get("User/GetUserByID", { params: { UserID: data.id } })
        .then((response) => {
          this.editedUser.userID = response.data.userID;
          this.editedUser.firstName = response.data.firstName;
          this.editedUser.lastName = response.data.lastName;
          this.editedUser.userName = response.data.userName;
          this.editedUser.provinceID = response.data.provinceID;
          this.editedUser.divisionID = response.data.divisionID;
          this.editedUser.branchID = response.data.branchID;
          this.editedUser.email = response.data.email;
          this.editedUser.address = response.data.address;
        });
    },
    updateData() {
      axios.post("User/UpdateUser", this.editedUser).then(function(response) {
        if (response.status == 200) {
          console.log(response);

          var formData = new FormData();

          //formData.append('ProfileImage', this.editedUser.profileImage);
          //formData.append(
          //    "Address",
          //    this.editedUser.address
          //);
          formData.append("FirstName", this.editedUser.firstName);
          formData.append("LastName", this.editedUser.lastName);
          formData.append("BranchID", this.editedUser.branchID);
          formData.append("DivisionID", this.editedUser.divisionID);
          formData.append("Email", this.editedUser.email);
          formData.append("Phone", this.editedUser.phone);
          formData.append("ProvinceID", this.editedUser.provinceID);
          formData.append("Role", this.editedUser.role);
          formData.append("UserID", this.editedUser.userID);
          //formData.append(
          //    "editedUser.UserID",
          //    this.editedUser.UserID
          //);

          axios.post("User/UpdateUser", formData).then(function(response) {
            if (response.status == 200) {
              console.log(response);
              location.reload();
              //if (response.data.success == true) {
              //    const userInformation = await axios.get(
              //        "User/GetUserByID", { params: { UserID: data.id } }
              //    );
              //    this.editedUser = userInformation.data;
              //}
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.user-profile {
  margin-top: 8em;
}

.update {
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.v-avatar {
  height: 6em !important;
  min-width: 6em !important;
  width: 6em !important;
  margin-top: -4em !important;
}

.edit-form {
  margin-top: 2em;
}

.v-icon {
  color: #1e88e5;
  font-size: 7em;
}
</style>
